import { Link } from "react-router-dom";
import Logo from "../assets/cosmicio.png";
import SearchInput from "./SearchInput";
import { menu } from "../utils/Constants";
import { useContext, useEffect, useState } from "react";
import { Context } from "../utils/ContextApi";
//import SearchResultIcon from "./SearchResultIcon";
import './styles.css';

const SearchResultsHeader = ({ searchQuery, setSearchQuery, navigate }) => {
  
    const [selectedMenu, setSelectedMenu] = useState("All");
    const { setImageSearch } = useContext(Context);

    useEffect(() => {
        return () => setImageSearch(false);
    }, [setImageSearch]);  // Added setImageSearch to the dependency array

    const clickHandler = (menu) => {
        //console.log('Clicked menu:', menu.name);  // Debugging line
        //console.log('Current search query:', searchQuery);  // Debugging line
        if (menu.url) {
            //console.log('Redirecting to:', menu.url + searchQuery);
             window.open(menu.url + searchQuery, '_blank'); // Redirect to external URL with search query
            return;
        }
        let isTypeImage = menu.name === 'Images';
        setSelectedMenu(menu.name);
        setImageSearch(isTypeImage ? true : false);
    };

    return (
        <div className="fixed-header p-[15px] pb-0 md:pr-5 md:pl-20 md:pt-7 border-b border-[#ebebeb] flex md:block flex-col items-center sticky top-0 bg-white">
        <div className="flex items-center justify-between w-full">
            <div className="flex items-center grow">
                <Link to="/">
                    <img
                        className="hidden md:block w-[92px] mr-10"
                        src={Logo}
                        alt="Logo"
                    />
                </Link>
                <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} navigate={navigate} from="searchResult" />
            </div>
             {/* Commenting out the icons section */}
            {/*
            <div className="hidden md:block">
                <SearchResultIcon />
            </div>
            */}
        </div>

        <div className="flex ml-[-12px] mt-3">
            {menu.map((menu, index) => (
                <span
                onClick={() => clickHandler(menu)}
                    key={index}
                    className={`flex items-center p-3 text-[#5f6368] cursor-pointer relative ${
                        selectedMenu === menu.name ? "text-[#1a73e8]" : ""
                    }`}>
                    <span className="hidden md:block mr-2">
                        {menu.icon}
                    </span>
                    <span className="text-sm">{menu.name}</span>
                    {selectedMenu === menu.name && (
                        <span className="h-[3px] w-[calc(100%-20px)] absolute bg-[#1a73e8] bottom-0 left-[10px]" />
                    )}
                </span>
            ))}
        </div>
    </div>
);
};
export default SearchResultsHeader;