import Airtable from 'airtable';

// Use the personal access token instead of the API key
const base = new Airtable({ apiKey: 'patjxopB5Hq86lF0G.de04ee0b5428f44cd3119d97d64c545cef2f7d63e59bfda8b0d1670260640a0a' }).base('appexJDSl5GkPbSOl');
const table = base('Table 1');

export function searchOffers(query, callback) {
  const matchingOffers = [];

  // You'll need to modify this query based on how you want to match offers to the query
  table.select({
    filterByFormula: `SEARCH(LOWER("${query}"), LOWER(Keywords))`,
    maxRecords: 3,
  }).eachPage((records, fetchNextPage) => {
    records.forEach(record => {
      const offer = {
        "Offer ID": record.id,
        "Offer Name": record.fields['Offer Name'],
        "Affiliate Link": record.fields['Affiliate Link'],
        "Keywords": record.fields['Keywords'],
        "Image URL": record.fields['Image URL'],
        "Description": record.fields['Description'],
      };
      matchingOffers.push(offer);
    });

    fetchNextPage();
  }, (err) => {
    if (err) {
      console.error(err);
      callback([]);
      return;
    }

    callback(matchingOffers);
  });
}
