import React from 'react'
import HomeHeader from './HomeHeader';
import Logo from '../assets/cosmicio.png'
import SearchInput, { performSearch } from './SearchInput'; 
import Footer from './Footer';
import './styles.css';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import { useState } from 'react';  // Import useState


const Home = () => {
  const navigate = useNavigate();  // Get navigate function
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <div className='h-[100vh] flex flex-col'>
        <HomeHeader />
        <main className='grow flex justify-center'>
            <div className='w-full px-5 flex flex-col items-center mt-44 '>
                <img className='w-[177px] md:w-[272px] mb-8' src={Logo} alt='cosmicio-logo' />
                <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} navigate={navigate} />
                <div className='flex gap-2 text-[]#3c4043 mt-8'>
                    <button 
                        className='search-btn h-9 px-4 bg-[#f8f9fa] text-sm rounded-md border border-[#f8f9fa] hover:border-[#dadce0] hover:shadow-c2'
                        onClick={() => performSearch(searchQuery, navigate)}
                    >
                        <div className='text-wrapper-2'>Search</div>
                    </button>

                    {/*<button className='h-9 px-4 bg-[#f8f9fa] text-sm rounded-md border border-[#f8f9fa] hover:border-[#dadce0] hover:shadow-c2'>
                        I'm feeling lucky
                    </button>*/}
                </div>
            </div>
       
        </main>
        <Footer />
    </div>
  )
}

export default Home