import React from 'react';
import Footer from './Footer';

const Advertising = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white text-gray-800">
      <div className="flex-grow py-10 md:py-20">
        <div className="max-w-3xl mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-semibold mb-6">Advertising with Cosmicio</h1>
          <div className="space-y-6">
            <p className="text-lg">
              At Cosmicio, we offer a unique opportunity for businesses to reach a diverse and engaged audience through our innovative search platform. Our cutting-edge AI-driven search engine connects users with the information they seek, and your brand could be a part of their discovery journey.
            </p>
            <p className="text-lg">
              By advertising with Cosmicio, you can leverage the power of personalized and contextual advertising to engage customers at the right moment. Our advanced targeting capabilities ensure that your message reaches the right audience, driving higher conversion rates and maximizing your return on investment.
            </p>
            <p className="text-lg">
              Whether you're a local business looking to increase foot traffic or an e-commerce brand aiming to boost online sales, we provide customized advertising solutions tailored to your specific goals and needs.
            </p>
            <p className="text-lg">
              Join the growing community of businesses that have chosen Cosmicio as their advertising partner. Contact us today to learn how we can help you connect with your target audience and grow your business on our platform.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Advertising;
