import { GoSearch } from "react-icons/go";
import { BsImage } from "react-icons/bs";
import { FiTrendingUp } from "react-icons/fi"; // Example icon for Trends
import { MdPlace } from "react-icons/md"; // Example icon for Maps
import { RiVideoLine } from "react-icons/ri"; // Example icon for Video

export const menu = [
    { name: "All", icon: <GoSearch /> },
    { name: "Images", icon: <BsImage size={14} /> },
    { name: "Trends", icon: <FiTrendingUp />, url: "https://trends.google.com/trends/explore?q=" },
    { name: "Maps", icon: <MdPlace />, url: "https://www.google.com/maps/place/" },
    { name: "Videos", icon: <RiVideoLine />, url: "https://www.youtube.com/results?search_query=" },
];




export const pagination = [
    { page: 1, startIndex: 1 },
    { page: 2, startIndex: 11 },
    { page: 3, startIndex: 21 },
    { page: 4, startIndex: 31 },
    { page: 5, startIndex: 41 },
    { page: 6, startIndex: 51 },
    { page: 7, startIndex: 61 },
    { page: 8, startIndex: 71 },
    { page: 9, startIndex: 81 },
    { page: 10, startIndex: 91 },
];