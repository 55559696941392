import React from 'react';
import Footer from './Footer';

const HowSearchWorks = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white text-gray-800">
      <div className="flex-grow py-10 md:py-20">
        <div className="max-w-3xl mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-semibold mb-6">How Search Works at Cosmicio</h1>
          <div className="space-y-6">
            <p className="text-lg">
              Cosmicio's search is built on a blend of innovative technologies and cutting-edge algorithms, aiming to provide a seamless and personalized search experience. Here's how it works:
            </p>
            <h2 className="text-2xl font-semibold mb-4">1. Query Processing</h2>
            <p className="text-lg">
              When you enter a search query, our system processes it using natural language processing (NLP) techniques. This allows us to understand the context and intent behind your query, leading to more accurate results.
            </p>
            <h2 className="text-2xl font-semibold mb-4">2. AI-Driven Search</h2>
            <p className="text-lg">
              We utilize AI models, including those provided by OpenAI, to search through vast amounts of information. The AI analyzes, filters, and ranks the results, ensuring that the most relevant content is presented to you.
            </p>
            <h2 className="text-2xl font-semibold mb-4">3. Personalization</h2>
            <p className="text-lg">
              Our search engine is designed to learn from your interactions and preferences. Over time, it adapts to your unique needs, delivering results that align with your interests and search habits.
            </p>
            <h2 className="text-2xl font-semibold mb-4">4. Integration with Various Sources</h2>
            <p className="text-lg">
              Cosmicio aggregates data from diverse sources, providing a wide range of results, including web pages, images, videos, and more. This ensures a comprehensive and multifaceted search experience.
            </p>
            <h2 className="text-2xl font-semibold mb-4">5. User Experience</h2>
            <p className="text-lg">
              Our intuitive interface and user-friendly design make searching easy and enjoyable. Features like collapsible sections, responsive design, and clean visuals contribute to a satisfying user experience.
            </p>
            <p className="text-lg">
              At Cosmicio, we are committed to pushing the boundaries of search technology and providing you with the tools to explore and connect with the world's knowledge. Experience the future of search with us.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default HowSearchWorks;
