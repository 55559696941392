import React, { useContext, useEffect, useState } from 'react'
import SearchResultHeader from './SearchResultHeader'
import Footer from './Footer'
import { useParams } from 'react-router-dom';
import { Context } from '../utils/ContextApi';
import { fetchDataFromApi, fetchFromOpenAI } from '../utils/api';  // Fixed duplicate import
import SearchResultTemplate from './SearchResultTemplate';
import SearchImageTemplate from './SearchImageTemplate';
import Pagination from './Pagination';
import './SearchResults.css';
import { searchOffers } from '../utils/db.js';
import { useNavigate } from 'react-router-dom';



const SearchResults = () => {
    const [result, setResult] = useState();
    const [openAIResponse, setOpenAIResponse] = useState(null);  // Moved inside the component
    const {query, startIndex} = useParams();
    const {imageSearch} = useContext(Context);
    const [ads, setAds] = useState([]);
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState(query);

    // Function to handle the toggle of the collapsible section
    const toggleOpenAIResponse = () => {
      const content = document.getElementById("mobileOpenAIResponseContent");
      if (content.style.display === "block") {
        content.style.display = "none";
      } else {
        content.style.display = "block";
      }
    };

    useEffect(() => {
        const fetchSearchResult = async () => {  // Defined async function inside useEffect
            let payload = {q: query, start: startIndex}
            if(imageSearch) {
                payload.searchType ='image'
            }
            
            try {
                const searchResult = await fetchDataFromApi(payload);
                setResult(searchResult);
                //console.log("Search Query:", query);

                searchOffers(query, (relevantAds) => {
                  //console.log("Relevant Ads:", relevantAds);
                  setAds(relevantAds);
                });
                
                const openAIResult = await fetchFromOpenAI(query);
                setOpenAIResponse(openAIResult.ai_response);
                //console.log("OpenAI Response:", openAIResult.ai_response);
            } catch (error) {
                console.error("Error fetching search results:", error);
                
            }
        };
        setSearchQuery(query);
        fetchSearchResult();
    }, [query, startIndex, imageSearch]);

    if(!result) {
        return <div>Loading...</div>;  // Provide feedback while data is loading
    }

    const { items, queries, searchInformation} = result;

    return (
        <div className='flex flex-col min-h-[100vh]'>
            <SearchResultHeader searchQuery={searchQuery} setSearchQuery={setSearchQuery} navigate={navigate} />


            {/* Mobile collapsible section */}
            <div id="mobileOpenAIResponse" className="mobile-openai-response">
              <button onClick={toggleOpenAIResponse}>Click to see ChatGPT response</button>
              <div id="mobileOpenAIResponseContent">
                {openAIResponse ? (
                  <div>
                    <h3>AI Response:</h3>
                    <p>{openAIResponse}</p>
                  </div>
                ) : (
                  <div>AI is thinking...</div>
                )}
              </div>
            </div>
          
            <main className='relative grow p-[12px] pb-0 md:pr-5 md:pl-20 pt-[20px] md:pt-[140px]'>
                <div className='flex text-sm text-[#70757a] mb-3'>
                    {`About ${searchInformation.formattedTotalResults} results (${searchInformation.formattedSearchTime} seconds)`}
                </div>
                
                {!imageSearch ? (
                    <>
                        {ads.map((ad, i) => <SearchResultTemplate key={i} data={ad} isAd={true} />)}
                        {items.map((item, i) => <SearchResultTemplate key={i} data={item} />)}
                    </>
                ) : (
                    <div className='grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6'>
                        {items.map((item, i) => <SearchImageTemplate key={i} data={item} />)}
                    </div>
                )}
            
                <Pagination queries={queries} />
              
                {!imageSearch && (
                    <div className="openai-response-container">
                        {openAIResponse ? (
                            <div>
                                <h3>AI Response:</h3>
                                <p>{openAIResponse}</p>
                            </div>
                        ) : (
                            <div>AI is thinking...</div>
                        )}
                    </div>
                )}
              
            </main>
            <Footer />
        </div>
    )
}

export default SearchResults;
