import React from 'react';
import Footer from './Footer';

const EmailLink = ({ user, domain }) => {
  return (
    <a href={`mailto:${user}@${domain}`}>
      {user}@{domain}
    </a>
  );
};

const TermsAndConditions = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white text-gray-800">
      <div className="flex-grow py-10 md:py-20">
        <div className="max-w-3xl mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-semibold mb-6">Terms and Conditions</h1>

          <h2 className="text-2xl font-semibold mb-2 mt-4">1. Acceptance of Terms</h2>
          <p className="text-lg">
            By accessing or using our website, services, products, or content (collectively referred to as the "Services"), you signify your agreement to comply with and be legally bound by these terms and conditions. These Terms govern your use of the Services and constitute a binding legal agreement between you and Adprime Marketing Solutions LTD. If you do not agree to these Terms, please refrain from using our Services.  

          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">2. User Obligations</h2>
          <p className="text-lg">
            As a user of our Services, you are responsible for complying with all applicable local, state, national, and international laws and regulations. This includes, but is not limited to, any laws regarding the transmission of technical data, copyright laws, and regulations governing the conduct of online activities. You must not engage in any behavior or upload, transmit, or distribute any content that is unlawful, fraudulent, threatening, abusive, defamatory, or otherwise objectionable as defined by applicable laws. Failure to comply with these obligations may result in the suspension or termination of your access to the Services.
          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">3. Intellectual Property</h2>
          <p className="text-lg">
            All content, logos, trademarks, and other intellectual property displayed on our site are the exclusive property of our company or our licensors. This includes but is not limited to texts, graphics, images, designs, icons, audio clips, and software. Any unauthorized use, reproduction, modification, distribution, or transmission of this intellectual property, without prior written permission from us, is strictly prohibited and may result in legal action. If you wish to use any of our intellectual property, please contact us to obtain the necessary permissions and guidelines.
          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">4. Disclaimers and Limitations of Liability</h2>
          <p className="text-lg">
            Our services are provided "as is" and "as available" without any express or implied warranties of any kind, including but not limited to the implied warranties of merchantability, fitness for a particular purpose, or non-infringement. We do not guarantee that the services will be uninterrupted, error-free, or free from harmful components, or that any defects will be corrected. Under no circumstances shall we be liable for any direct, indirect, incidental, special, or consequential damages, loss of profits, or other losses arising out of or in connection with the use or inability to use our services, even if advised of the possibility of such damages. Our total liability is limited to the fullest extent permitted by law.
          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">5. Third-Party Links and Services</h2>
          <p className="text-lg">
            Our services may contain links to websites, content, or resources provided by third parties, including advertisers and partners. These third-party links and services are not under our control, and we are not responsible for the content, practices, policies, or any other aspect of these third-party sites. The inclusion of such links does not imply endorsement or recommendation of the linked services, and we encourage you to review the terms, conditions, privacy policies, and other regulations of each third-party service you access through our platform. We shall not be liable for any loss, damage, or other consequences arising from your use or reliance on third-party content or services.
          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">6. Termination of Service</h2>
          <p className="text-lg">
            We reserve the right, in our sole discretion and without prior notice, to terminate, suspend, or limit your access to the services provided on our platform. Such termination may occur under circumstances deemed appropriate by law, including but not limited to breaches of these terms and conditions, suspected fraudulent or illegal activity, or failure to comply with applicable laws and regulations. Upon termination, your right to use the services will cease immediately. Please note that certain provisions of these terms, including but not limited to disclaimers, limitations of liability, and indemnification obligations, shall survive termination and continue to apply even after your access to the services has been terminated.

          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">7. Governing Law</h2>
          <p className="text-lg">
            These terms and conditions, and any dispute or claim arising out of or in connection with them or their subject matter or formation (including non-contractual disputes or claims), shall be governed by and construed in accordance with the laws of Cyprus. The courts of Cyprus shall have exclusive jurisdiction to settle any dispute or claim that arises out of or in connection with these terms or their formation.
          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">8. Changes to the Terms</h2>
          <p className="text-lg">
            We reserve the right, at our sole discretion, to modify or replace these terms at any time. If a revision is material, we will make reasonable efforts to provide at least 30 days' notice prior to any new terms taking effect. The method of notification may include, but is not limited to, email notifications, or notifications and announcements on our website. It is your responsibility to review these terms periodically for changes. By continuing to access or use our services after any revisions become effective, you agree to be bound by the revised terms.
          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">9. Contact Us</h2>
          <p className="text-lg">
            For any questions or concerns about these terms, please contact us at <EmailLink user="legal" domain="cosmicio.com" />.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
