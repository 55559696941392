import React from 'react'



const Footer = () => {


    const quickLinks = [
        { text: "Home", url: "/" },
        { text: "About", url: "/about" },
        { text: "Advertising", url: "/advertising" },
        //{ text: "Business", url: "/business" },
        { text: "How we work", url: "/how-search-works" },
    ];
    
    const settingMenu = [
        { text: "Privacy", url: "/privacy" },
        { text: "Terms", url: "/terms" },
    ];
    

    //const settingMenu = ["Privacy", "Terms""Settings"];
    

  return (
    <footer className="bg-[#F9F6F2]">
      {/*
    <div className="flex py-[15px] px-[15px] md:px-[30px] border-b border-[#dadce0]">
        <span className="text-[#70757a] text-[15px] leading-none">
            
        </span>
    </div>
      */}

    <div className="flex flex-col md:flex-row justify-between py-3 md:py-0 md:px-[15px] border-b border-[#dadce0]">
        <div className="flex justify-center">
            {quickLinks.map((menu, index) => (
                <a
                    key={index} href={menu.url}
                    className="text-[#70757a] text-[12px] md:text-[14px] leading-none p-[10px] md:p-[15px]"
                >
                    {menu.text}
                </a>
            ))}
        </div>

        <div className="flex justify-center">
            {settingMenu.map((menu, index) => (
                <a
                    key={index} href={menu.url}
                    className="text-[#70757a] text-[12px] md:text-[14px] leading-none p-[10px] md:p-[15px]"
                >
                    {menu.text}
                </a>
            ))}
        </div>
    </div>
</footer>
  )
}

export default Footer