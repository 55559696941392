import { useState, useEffect } from "react";
import { FiChevronRight, FiChevronLeft } from "react-icons/fi";
import { useParams, useNavigate } from "react-router-dom";

//import Logo from "../assets/cosmicio.png";
import { pagination } from "../utils/Constants";

const Pagination = ({ queries }) => {
    const { query } = useParams();
    const [page, setPage] = useState(pagination[0].startIndex);
    const navigate = useNavigate();

    useEffect(() => {
        setPage(pagination[0].startIndex);
    }, [query]);

    const paginationClickHandler = (startIndex) => {
        setPage(startIndex);
        navigate(`/${query}/${startIndex}`);
    };

    return (
        <div className="flex flex-col items-center py-14 max-w-[700px]">
            <div className="flex items-center">
                {queries.previousPage && (
                    <div
                        onClick={() =>
                            paginationClickHandler(queries.previousPage[0].startIndex)
                        }
                    >
                        <FiChevronLeft size={20} className="cursor-pointer" />
                    </div>
                )}
            
                <div className="flex gap-3 text-[#4285f4] text-sm">
                    {pagination.map((p) => (
                        <span
                            key={p.page}
                            onClick={() => paginationClickHandler(p.startIndex)}
                            className={`cursor-pointer ${
                                page === p.startIndex ? "text-black" : ""
                            }`}
                        >
                            {p.page}
                        </span>
                    ))}
                </div>
            
                {queries.nextPage && (
                    <div
                        onClick={() =>
                            paginationClickHandler(queries.nextPage[0].startIndex)
                        }
                    >
                        <FiChevronRight size={20} className="cursor-pointer" />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Pagination;