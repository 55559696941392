import React from 'react';
import Footer from './Footer';

const EmailLink = ({ user, domain }) => {
  return (
    <a href={`mailto:${user}@${domain}`}>
      {user}@{domain}
    </a>
  );
};

const PrivacyPolicy = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white text-gray-800">
      <div className="flex-grow py-10 md:py-20">
        <div className="max-w-3xl mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-semibold mb-6">Privacy Policy</h1>
          <p className="text-lg">Last Updated: 23 August 2023</p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">1. Information Collection and Use</h2>
          <p className="text-lg">
            <br /><strong>a. Types of Information Collected:</strong><br />
            We may collect the following types of information from you:
            <br /><br />
            <strong>Personal Information:</strong> Including but not limited to your name, email address, phone number, and physical address. This information may be collected when you register, sign in, or fill out forms on our platform.
            <br />
            <strong>Technical Information:</strong> This encompasses data such as your IP address, device type, operating system, browser type, and other related technical information that helps us optimize your experience.
            <br />
            <strong>Usage Information:</strong> Information about how you interact with our services, such as search queries, pages visited, time spent on pages, and links clicked.
            <br />
            <strong>Cookies and Similar Technologies:</strong> We use cookies and similar tracking technologies to recognize you and personalize your experience. More details on how to manage these can be found in the cookies tab.
            <br /><br />
            <strong>b. How We Use Your Information:</strong><br />
            We utilize the collected information for various purposes:
            <br />
            - To Provide Services
            <br />
            - For Marketing and Communications
            <br />
            - To Share with Advertising Partners
            <br />
            - For Analytics and Improvement
            <br />
            - For Legal Compliance
            <br /><br />
            <strong>c. Your Choices and Control:</strong><br />
            You can manage your preferences, opt-out, or request deletion or modification of your information by emailing us at <EmailLink user="legal" domain="cosmicio.com" />.
          </p>

          

          <h2 className="text-2xl font-semibold mb-2 mt-4">2. Information Sharing</h2>
          <p className="text-lg">
            We may share your query with OpenAI to receive a response and share data with our advertising partners.<br /><br />
            <strong>OpenAI Collaboration:</strong> Your queries may be processed by OpenAI to deliver an accurate response.
            <br />
            <strong>Advertising Partners:</strong> We may share data to enhance the effectiveness of advertisements tailored to your interests.
            <br />
            <strong>International Data Transfers:</strong> We maintain all necessary safeguards and comply with applicable legal requirements for international data transfers.
          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">3. Cookies and Tracking Technologies</h2>
          <p className="text-lg">
            We employ cookies and similar tracking technologies to enhance your browsing experience and provide customized services. The utilization of these technologies is aligned with the prevailing legal standards. <br /><br />
            <ul>
              <li><strong>Usage and Purpose:</strong> Cookies help us understand user preferences, optimize site functionality, and deliver targeted content.</li>
              <li><strong>Management:</strong> You can manage, opt-out, or delete cookies through your browser settings or the dedicated cookies tab on our platform.</li>
              <li><strong>Third-Party Cookies:</strong> We may collaborate with third-party partners who use cookies for analytics, advertising, and other purposes, subject to their privacy policies.</li>
              <li><strong>Legal Compliance:</strong> Our use of cookies and tracking technologies is in strict compliance with applicable data protection laws and regulations.</li>
            </ul>
          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">4. Children's Privacy</h2>
          <p className="text-lg">
            Our services are designed for a general audience and are not intentionally targeted at children under the age of 13 (or the relevant age limit in your jurisdiction). We do not knowingly collect or solicit personal information from individuals in this age group.<br /><br />
            <ul>
              <li><strong>Parental Control:</strong> Parents or guardians who believe that their child has submitted personal information to us without their consent should contact us immediately at <EmailLink user="support" domain="cosmicio.com"/>. We will take steps to promptly remove such information from our records.</li>
              <li><strong>Compliance with Laws:</strong> We comply with all applicable laws and regulations, including the Children's Online Privacy Protection Act (COPPA) and other local regulations pertaining to the protection of children's privacy online.</li>
            </ul>
          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">5. User Rights</h2>
          <p className="text-lg">
            As a user, you have specific rights regarding the personal information we hold about you. These rights include:<br /><br />
            <ul>
              <li><strong>Access:</strong> You have the right to request a copy of your personal information that we process.</li>
              <li><strong>Deletion:</strong> You can request the deletion of your personal data, subject to applicable legal requirements.</li>
              <li><strong>Correction:</strong> If you believe that any information we hold about you is incorrect or incomplete, you can request corrections.</li>
              <li><strong>Objection and Restrictions:</strong> You may object to our processing of your personal information or ask us to restrict processing in certain circumstances.</li>
              <li><strong>Portability:</strong> You have the right to request that we transfer the information that we have collected to another organization, or directly to you, under certain conditions.</li>
            </ul>
            To exercise any of these rights or for more information, please contact us at <EmailLink user="legal" domain="cosmicio.com"/>. We are committed to responding to your requests within the time frames required by law.
          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">6. Security</h2>
          <p className="text-lg">
          We are committed to ensuring the security of your personal information. To prevent unauthorized access, maintain data accuracy, and ensure the appropriate use of information, we have put in place suitable physical, electronic, and managerial procedures to safeguard and secure the information we collect online. These measures include, but are not limited to:<br /><br />
          <ul>
            <li><strong>Encryption:</strong> We utilize strong encryption to protect data during transmission.</li>
            <li><strong>Access Control:</strong> We limit access to personal information to authorized personnel who have legitimate business needs.</li>
            <li><strong>Monitoring:</strong> We continuously monitor our systems for potential vulnerabilities and attacks, implementing necessary updates and patches.</li>
            <li><strong>Compliance:</strong> We regularly review our compliance with our privacy policy, applicable laws, and industry standards.</li>
          </ul>
          Please be aware that no security measures are perfect or impenetrable. While we strive to protect your personal information, we cannot guarantee its absolute security. If you have any questions about our security practices, please contact us at <EmailLink user="security" domain="cosmicio.com"/>.
        </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">7. Changes to This Policy</h2>
          <p className="text-lg">
            We reserve the right to update or modify this Privacy Policy at any time and from time to time without prior notice. Such changes, modifications, additions, or deletions shall be effective immediately upon posting on this page. We encourage you to review this Privacy Policy periodically to stay informed about how we are protecting the personal information we collect. If you are a registered user, we may also notify you of material changes to this policy by sending an email to the email address associated with your account. Your continued use of our platform after any modification to this Privacy Policy will constitute your acceptance of such modification.
          </p>

          <h2 className="text-2xl font-semibold mb-2 mt-4">8. Contact Us</h2>
          <p className="text-lg">
            For any questions or concerns about this policy, please contact us at <EmailLink user="hello" domain="cosmicio.com"/>.
          </p>
        </div>
      </div>
      

      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
