import React from 'react'

const SearchResultTemplate = ({data, isAd = false}) => {

    function createMarkup(html) {
        return {__html: html};
    }

    // Use affiliate link for ads, otherwise use the provided link
    const link = isAd ? data['Affiliate Link'] : data.link;

    // Use "Offer Name" for ads, otherwise use the provided title
    const title = isAd ? data['Offer Name'] : data.title;

    return (
        <div className="flex flex-col py-3 max-w-[700px]">
            <div
                className="group cursor-pointer"
                onClick={() => window.open(link, "_blank")}
            >
                {isAd && <span className="text-xs bg-yellow-200 px-2 py-1 rounded mb-1">Ad</span>}
                <div className="text-sm truncate text-[#202124] mb-1">
                    {link}
                </div>
                <div className="group-hover:underline text-xl text-[#1a0dab] pt-2">
                    {title}
                </div>
            </div>
            <div
                className="text-sm text-[#4d5156] leading-6 pt-1"
                dangerouslySetInnerHTML={createMarkup(isAd ? data['Description'] : data.htmlSnippet)}
            />
        </div>
    )
}

export default SearchResultTemplate;
