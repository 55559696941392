import {BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import SearchResults from "./components/SearchResults";
import { AppContext } from "./utils/ContextApi";
import About from "./components/About";
import Advertising from "./components/Advertising";
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import HowSearchWorks from "./components/HowSearchWorks";

function App() {

  return (
    <AppContext>
 <BrowserRouter>
  <Routes>
    <Route path="/" exact element={<Home />} />
    <Route path="/:query/:startIndex" exact element={<SearchResults />} />
    <Route path="/about" element={<About />} />
    <Route path="/advertising" element={<Advertising />} />
    <Route path="/terms" element={<Terms />} />
    <Route path="/privacy" element={<Privacy />} />
    <Route path="/how-search-works" element={<HowSearchWorks />} />
  </Routes>
   </BrowserRouter>     
   </AppContext>
  );
}

export default App;
