
import axios from "axios";

const BASE_URL = 'https://www.googleapis.com/customsearch/v1'

// API Key von Github
//const params = {
//    key: 'AIzaSyDMNCxd-5TUNl7attneZb6OiBP0ov-t1x8',
//    cx: 'a1ebcfabc96294090',
//}

const params = {
    key: 'AIzaSyCLT5MB71SrYdt8cx8TxwbrXyYP4ZpjQKo',
    cx: '5693ac91267334e0b',
}

export const fetchFromOpenAI = async (query) => {
    try {
        const response = await axios.post('https://cosmicio.com/ask', {
            message: query
        });
        console.log("Response:", response.data);
        return response.data;
    } catch (error) {
        console.log("Error:", error);
    }
};



export const fetchDataFromApi = async (payload) => {

    const {data} = await axios.get(BASE_URL , {
        params : {
            ...params, ...payload
        }
    })

    return data;

}