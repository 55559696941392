import React from 'react';
import Footer from './Footer';

const About = () => {
  return (
    <div className="flex flex-col min-h-screen bg-white text-gray-800">
      <div className="flex-grow py-10 md:py-20">
        <div className="max-w-3xl mx-auto px-4">
          <h1 className="text-3xl md:text-4xl font-semibold mb-6">About Us</h1>
          <div className="space-y-6">
            <p className="text-lg">
              At Cosmicio, we are driven by a profound passion for artificial intelligence and its limitless potential to transform the way we search, discover, and interact with the world.
            </p>
            <p className="text-lg">
              By integrating cutting-edge AI technology with an intuitive search experience, we empower users to explore and connect with information like never before. Our mission is to make search smarter, faster, and more personalized.
            </p>
            <p className="text-lg">
              Our team of AI enthusiasts and engineers is dedicated to pushing the boundaries of innovation and creating tools that put the power of knowledge at your fingertips. We believe in a future where AI and search work seamlessly together, unlocking new horizons of understanding and creativity.
            </p>
            <p className="text-lg">
              Join us on this exciting journey, and experience the future of search with Cosmicio.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default About;
